import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { evoConfigurationUrl, ParamObject } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { EvoConfigurationState } from '../types';

export const evoConfiguration = (
    actionName?: string,
    processResultFunction?: Function,
    _paramObject: ParamObject = {},
): Observable<{
    result: EvoConfigurationState;
    actionName?: string;
    processResultFunction?: Function;
}> => {
    const url = evoConfigurationUrl();
    const obs = sendRetrieveRequest(url);
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const config = convertToCamel<any>(response);
            return observableOf({ result: config?.gooseConfig, actionName, processResultFunction });
        }),
    );
};
